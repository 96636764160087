
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-drawer-association-contract',
  mixins: [apiEndpoint],
  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      api_url: '',
      load: false,

      formData: {
        tranche_id: '',
        entity_info_id: '',
        contract_number: '',
        contract_name: '',
        adjustable_advance_amount: '',
        min_age: '',
        max_age: '',
        id: '',
        sign_date: '',
        start: '',
        end: '',
        target: '',
        amount: '',
        contribution_percentage: '',
        status_remarks: '',
        age_grace_in_month: '',
        file_names: [] as any,
      } as any,
      tranche: [],
      association: [],
      files: null as any,

      loading: false,
      url: '',
      entity_logo: '',
    };
  },
  async created() {
    this.load = true;
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
    await this.associationList();
    this.emitter.on('contract-edit-modal-data', async (data) => {
      this.load = true;
      this.formData = data;
      console.log(JSON.stringify(data));
      //this.entity_logo = this.formData?.attach_file_info?.file_path;
      this.load = false;
    });
  },
  methods: {
    updateFile(e) {
      // this.formData.file_names = e.target.files[0];
      // this.url = URL.createObjectURL(this.formData.file_names);
      for (var key in e.target.files) {
        this.formData.file_names[key] = e.target.files[key];
      }
      console.log(this.formData);
    },
    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();

      //formData.set("file_names", this.formData.file_names);
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);

      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }

      this.loading = true;
      await ApiService.post('entity/contract/update', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              DrawerComponent?.hideAll();
              this.emitter.emit('association-updated');
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
