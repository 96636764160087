
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-drawer-entity-edit',
  mixins: [apiEndpoint],
  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      api_url: '',
      drawer: {},
      load: false,
      dialogImageUrl: '',
      imageUrl: '',
      dialogVisible: false,
      entityInfos: [],

      trainingInstituteInfos: [],
      entityTypes: [],
      avatarFiles: [],

      formData: {
        tranche_id: '',
        entity_type_id: '',
        email: '',
        telephone: '',
        industry_sector: '',
        web_url: '',
        address: '',
        id: '',
        postcode: '',
        division: '',
        district: '',
        sub_district: '',
        entity_description: '',
        status: '',
        active_status: '1',
        status_remarks: '',
        fax: '',
        name: '',
        entity_short_name: '',
        registration_number: '',
        registration_date: '',
        registration_authority: '',
        file_name: '',
        is_bill_author: '',
        logo: '',
      } as any,
      tranche: [],
      entityList: [],
      sector: [],
      divisions: [],
      district: [],
      upazila: [],
      loading: false,
      url: '',
      entity_logo: '',
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;

    this.emitter.on('entity-edit-modal-data', async (data) => {
      this.load = true;
      await this.getTranche();
      await this.getEntityType();
      await this.getSectorList();
      await this.getDivision();
      await this.getDistrict();
      await this.getUpazila();
      this.formData = data;
      this.entity_logo = this.formData?.attach_file_info?.file_path;
      await this.entityLog();
    });
  },
  methods: {
    updatePhoto(e) {
      this.formData.logo = e.target.files[0];
      this.url = URL.createObjectURL(this.formData.logo);
    },
    async formSubmit() {
      let formData = new FormData();

      formData.set('entity_logo', this.formData.logo);
      let user_id = VueCookieNext.getCookie('_seip_user');
      let user_role_id = VueCookieNext.getCookie('_seip_role_id');
      formData.set('user_id', user_id.id);
      formData.set('user_role_id', user_role_id);

      for (var key in this.formData) {
        if (this.formData[key]) {
          formData.set(key, this.formData[key]);
        }
      }

      this.loading = true;
      await ApiService.post('entity/update', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              DrawerComponent?.hideAll();
              this.emitter.emit('entity-updated');
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async entityLog() {
      this.load = true;
      await ApiService.get('entity/edit/' + this.formData.id)
        .then((response) => {
          this.load = false;
          if (response.data.data) {
            this.load = false;
            this.formData = response.data.data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDivision() {
      await ApiService.get('configurations/geo_division/list')
        .then((response) => {
          this.divisions = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get(
        'configurations/geo_district/list?division=' + this.formData.division
      )
        .then((response) => {
          this.district = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getUpazila() {
      await ApiService.get(
        'configurations/geo_upazila/list?division=' +
          this.formData.division +
          '&district=' +
          this.formData.district
      )
        .then((response) => {
          this.upazila = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityType() {
      await ApiService.get('configurations/entity_type/list')
        .then((response) => {
          this.entityList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSectorList() {
      await ApiService.get('configurations/industry_sector/list')
        .then((response) => {
          this.sector = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
